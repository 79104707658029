import axios from "axios";
import {
    URLAPI,
    WEBPAY_CREATE_PATH
} from "../config";
import {LOGOUT} from './users'

const init = {
    _create_webpay: {
        data: {},
        status: 0,
        message: {},
    },
}

export const CREATE_WEBPAY_SUCCESS = "CREATE_WEBPAY_SUCCESS";


export const webpay_module = (state = init, action) => {
    switch (action.type) {
        case CREATE_WEBPAY_SUCCESS:
            return {
                ...state,
                _create_webpay: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}


export const create_webpay = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${WEBPAY_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_WEBPAY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_WEBPAY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_WEBPAY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const clear_status_webpay = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}

