import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI} from "../../config";
import {
    delete_item_shopping_cart,
    DELETE_ITEM_SHOPPING_CART_SUCCESS,
    clear_status_shopping_cart,
    get_all_shopping_cart,
    update_shopping_cart,
    UPDATE_SHOPPING_CART_SUCCESS
} from "../../redux/shopping_carts";
import Modal from "../../components/modal";
import Input from "../../components/input";
import SelectInput from "../../components/select";
import {search_values_attributes} from "../../redux/items";
import {
    create_quotation,
    get_all_quotation,
    clear_status_quotation,
    CREATE_QUOTATION_SUCCESS
} from "../../redux/quotation";
import Whatsapp from "../../components/whatsapp";

const Carts = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [variants, setVariants] = useState([]);

    const [datas, setDatas] = useState([]);

    const _get_all_shopping_cart = useSelector((state) => state.shopping_cart_module._get_all_shopping_cart);
    const _delete_item_shopping_cart = useSelector((state) => state.shopping_cart_module._delete_item_shopping_cart);
    const _update_shopping_cart = useSelector((state) => state.shopping_cart_module._update_shopping_cart);
    const _get_all_city = useSelector((state) => state.city_module._get_all_city);

    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        id_usuario: '',
        identificacion: '',
        nombres: '',
        apellidos: '',
        correo: '',
        telefono: '',
        id_ciudad: 0,
        ciudad: '',
        direccion: '',
    });

    const [form, setForm] = useState({
        id: 0,
        titulo: '',
        id_item: 0,
        cantidad: 0,
        valores_atributos: [],
    });

    const [modalDetail, setModalDetail] = useState(false);

    useEffect(() => {
        if (_get_all_city && _get_all_city.data && _get_all_city.data.datos) {
            let values = _get_all_city.data.datos.filter(x => x.activo === true);
            setCities(values);
        }
    }, [_get_all_city]);

    useEffect(() => {
        setIsLoading(true);
        if (_get_all_shopping_cart &&_get_all_shopping_cart.data && _get_all_shopping_cart.data.datos) {
            setDatas(_get_all_shopping_cart.data.datos);
            setIsLoading(false);
            let vari = [];
                // eslint-disable-next-line
                _get_all_shopping_cart.data.datos.map((x) => {
                    vari.push({
                        'id_variante': x.id_variante,
                        'id_detalle': x.id,
                        'cantidad': x.cantidad,
                        'id_quotation': x.id_quotation
                    });
                })
                setVariants(vari)
        }
        if (_get_all_shopping_cart && _get_all_shopping_cart.data && _get_all_shopping_cart.data.datos && _get_all_shopping_cart.data.datos.length > 0) {
            let value = _get_all_shopping_cart.data;
            console.log(value)
            let city = 0;
            if (value.datos[0].id_ciudad !== null) {
                city = value.datos[0].id_ciudad
            }
            /*  else if (value.datos[0].id_ciudad === null) {
                document.getElementById("id_ciudad").selectedIndex = 0;
            } */
            setFormData({
                id_usuario: value.usuario.id,
                identificacion: value.usuario.identificacion,
                nombres: value.usuario.nombres,
                apellidos: value.usuario.apellidos,
                correo: value.usuario.correo,
                telefono: value.usuario.telefono,
                id_ciudad: city,
                ciudad: value.datos[0].ciudad,
                direccion: value.datos[0].direccion,
            })
        }
    }, [_get_all_shopping_cart, isLoading]);

    useEffect(() => {
        if (_delete_item_shopping_cart.status === 200) {
            dispatch(clear_status_shopping_cart(DELETE_ITEM_SHOPPING_CART_SUCCESS));
            history.push(`/carrito-compra`);
            dispatch(get_all_shopping_cart(token));
        } else if (_delete_item_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(DELETE_ITEM_SHOPPING_CART_SUCCESS));
            toast.error(_delete_item_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_delete_item_shopping_cart, dispatch, history, token]);

    useEffect(() => {
        if (_update_shopping_cart.status === 200) {
            dispatch(clear_status_shopping_cart(UPDATE_SHOPPING_CART_SUCCESS));
            history.push(`/carrito-compra`);
            dispatch(get_all_shopping_cart(token));
            setModalDetail(false);
        } else if (_update_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(UPDATE_SHOPPING_CART_SUCCESS));
            toast.error(_update_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_update_shopping_cart, dispatch, history, token]);

    function deleteitem(id) {
        dispatch(delete_item_shopping_cart(token, id));
    }

    function edit_item(id) {
        setModalDetail(true);
        let value = datas.filter((x) => x.id === id);
        if (value.length > 0) {
            setForm({
                ...form,
                id: id,
                titulo: value[0].titulo,
                id_item: value[0].id_item,
                cantidad: value[0].cantidad,
                valores_atributos: value[0].valores
            })
        }
    }

    async function getAttributeValue(id_attribute_value, id_attribute_value_fiter, atributo, y) {
        setIsLoading(true);
        const params = '?id_valor_atributo=' + id_attribute_value + '&&atributo=' + atributo + '&&id_item=' + form.id_item + '&&id_valor_atributo_filtro=' + id_attribute_value_fiter;
        const response = await search_values_attributes(token, params);
        if (response.status === 200) {
            if (response.data.datos.length > 0) {
                let va = [];
                // eslint-disable-next-line
                form.valores_atributos.map((m, n) => {
                    if (n < y) {
                        va.push(m);
                    }
                    if (n === y) {
                        let filter = form.valores_atributos[y].valores.filter(a => a.id === id_attribute_value_fiter);
                        va.push({
                            'id': id_attribute_value_fiter,
                            'atributo': m.atributo,
                            'valor': filter[0].nombre,
                            'valores': m.valores
                        })
                    }

                    if (n === (y + 1)) {
                        va.push({'id': 0, 'atributo': m.atributo, 'valor': '', 'valores': response.data.datos});
                    }
                    if (n > (y + 1)) {
                        va.push({'id': 0, 'atributo': m.atributo, 'valor': '', 'valores': []});
                    }
                })
                setForm({
                    ...form,
                    valores_atributos: va
                });
            }
        }
        setIsLoading(false);
    }

    const handleChangeQty = (e) => {
        setForm({
            ...form,
            cantidad: e.target.value
        })
    }

    const handleChange = (e, y) => {
        // eslint-disable-next-line
        form.valores_atributos.map((a, b) => {
            if (b > y) {
                document.getElementById(a.atributo).selectedIndex = 0;
                form.valores_atributos[b].valores = []
            }
        })
        if ((y + 1) < form.valores_atributos.length) {
            const id_attribute_value = (y === 0) ? parseInt(e.target.value) : form.valores_atributos[0].id;
            getAttributeValue(id_attribute_value, parseInt(e.target.value), form.valores_atributos[y + 1].atributo, y)

        } else {
            let filter = form.valores_atributos[y].valores.filter(a => a.id === parseInt(e.target.value))
            form.valores_atributos[y].id = parseInt(e.target.value);
            form.valores_atributos[y].valor = filter[0].nombre;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (parseFloat(form.cantidad) <= 0) {
            toast.error("La cantidad debe ser mayor a 0", {position: toast.POSITION.TOP_RIGHT});
            return
        }

        let valid = true;
        // eslint-disable-next-line
        form.valores_atributos.map((x) => {
            if (x.id === 0) {
                toast.error("Debe seleccionar una opción para " + x.atributo, {position: toast.POSITION.TOP_RIGHT});
                valid = false;
                // eslint-disable-next-line
                return;
            }
        })
        if (valid === true) {
            let values = [];
            // eslint-disable-next-line
            form.valores_atributos.map((item) => {
                values.push(item.id)
            })
            const data = new FormData();
            data.append('id_item', form.id_item);
            data.append('cantidad', form.cantidad);
            data.append('valores', JSON.stringify(values));
            dispatch(update_shopping_cart(token, form.id, data));
        }
    };

    const handleChangeInfo = (e) => {
        if (e.target.name === "id_ciudad") {
            setFormData({
                ...formData,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const _create_quotation = useSelector((state) => state.quotation_module._create_quotation);

    useEffect(() => {
        if (_create_quotation.status === 201) {
            dispatch(clear_status_quotation(CREATE_QUOTATION_SUCCESS));
            setModalConfirm(true);
            setIsLoading(false);
        } else if (_create_quotation.status !== 0) {
            dispatch(clear_status_quotation(CREATE_QUOTATION_SUCCESS));
            toast.error(_create_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_quotation, dispatch, history, token]);

    const handleSubmitQuotation = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (formData.identificacion === "") {
            toast.error("Debe ingresar la identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.apellidos === "") {
            toast.error("Debe ingresar los apellidos", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.telefono === "") {
            toast.error("Debe ingresar el teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.id_ciudad === 0) {
            toast.error("Debe seleccionar una ciudad", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (formData.direccion === "") {
            toast.error("Debe ingresar una dirección", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        const data = new FormData();
        data.append('id_usuario', formData.id_usuario);
        data.append('id_ciudad', formData.id_ciudad);
        data.append('direccion', formData.direccion);
        data.append('variantes', JSON.stringify(variants));
        dispatch(create_quotation(token, data));
    };

    return (
        <>
            <div className="text-center mt-3">
                <h3>Carrito de cotizaciones</h3>
            </div>
            <div class="shopping_cart_area">
                {isLoading && <LoadingSpinner/>}
                <div><ToastContainer/></div>
                <div class="container">  
                    <div class="row">
                        <div class="col-12">
                            <div class="table_desc">
                                <div class="cart_page table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="product_remove">Opciones</th>
                                                <th class="product_thumb">Imagen</th>
                                                <th class="product_name">Producto</th>
                                                <th class="product_quantity">Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datas.map((item) => (
                                                <tr>
                                                    <td class="product_remove">
                                                        <a onClick={() => {edit_item(item.id);}} className="mr-3"><i class="fa fa-pencil-square-o"></i></a>
                                                        <a onClick={() => {deleteitem(item.id);}}><i class="fa fa-trash-o"></i></a>
                                                    </td>
                                                    <td class="product_thumb"><img src={URLAPI + item.imagenes[0].url} alt="" style={{width: '60%'}}/></td>
                                                    <td class="product_name">{item.titulo}</td>
                                                    <td class="product_quantity">{item.cantidad}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>   
                                </div>  
                            </div>
                        </div>
                        {(datas.length > 0) &&
                            <div class="coupon_area">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="coupon_code left">
                                            <h3>Contacto</h3>
                                            <div class="coupon_inner" style={{marginTop: 15}}>   
                                                <strong>Identificación: </strong>{formData.identificacion}<br/>                                
                                                <strong>Nombres: </strong>{formData.nombres}<br/>
                                                <strong>Apellidos: </strong>{formData.apellidos}<br/>
                                                <strong>Correo electrónico: </strong>{formData.correo}<br/>
                                                <strong>Teléfono: </strong>{formData.telefono}<br/>
                                            </div>    
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="coupon_code right">
                                            <h3>Información de envío</h3>
                                            <div class="coupon_inner">
                                                <div className="form-group">
                                                    <label>Ciudad</label>
                                                    <SelectInput
                                                        label="Ciudad"
                                                        id="id_ciudad"
                                                        name="id_ciudad"
                                                        value={formData.id_ciudad}
                                                        select={formData.ciudad}
                                                        onChange={handleChangeInfo}
                                                        options={cities ? cities.map((item) => ({
                                                            name: item.nombre,
                                                            id: item.id,
                                                        })) : []}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Input
                                                        label="Dirección"
                                                        name="direccion"
                                                        value={formData.direccion}
                                                        onChange={handleChangeInfo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="checkout_btn mt-3">
                                    <a href="#" style={{marginRight: 10}} onClick={() => {
                                        history.push(`/`);
                                        document.getElementById("catalogo").className = "active";
                                        document.getElementById("cotizaciones").className = "";
                                        document.getElementById("fabricaciones").className = "";
                                        document.getElementById("carrito").className = "";
                                    }}>Seguir cotizando</a>
                                    <a href="#" type="button" onClick={handleSubmitQuotation}>Continuar</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>   
            </div>

            <Modal className="modal-main div-modal" show={modalDetail}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{form.titulo}</h5>
                        <div className="form-group">
                            <Input
                                type="number"
                                id="Cantidad"
                                label="Cantidad"
                                name="Cantidad"
                                value={form.cantidad}
                                onChange={handleChangeQty}
                            />
                        </div>
                        {(form.valores_atributos.length > 0) && (
                            form.valores_atributos.map((x, y) => (
                                <div className="form-group">
                                    <small className="texto2">{x.atributo}</small>
                                    <SelectInput
                                        id={x.atributo}
                                        label={x.atributo}
                                        name={x.atributo}
                                        select={x.valor}
                                        onChange={(e) => handleChange(e, y)}
                                        options={(x.valores) ? x.valores.map((item) => ({
                                            name: item.nombre,
                                            id: item.id,
                                        })) : []}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                    <div className="card-footer text-center">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Actualizar
                            detalle
                        </button>
                        <button type="button" onClick={() => {
                            setModalDetail(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalConfirm}>
                {isLoading && <LoadingSpinner/>}
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Cotización registrada</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Se ha ingresado tu cotización a nuestro sistema un
                            asesor te contestará a la mayor brevedad posible.</p>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" onClick={() => {
                        history.push(`/`);
                        document.getElementById("catalogo").className = "active";
                        document.getElementById("cotizaciones").className = "";
                        document.getElementById("fabricaciones").className = "";
                        document.getElementById("carrito").className = "";
                        dispatch(get_all_quotation(token, 1));
                        dispatch(get_all_shopping_cart(token));
                        modalConfirm(false);
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido
                    </button>
                </div>
            </Modal>
            <Whatsapp/>
        </>
    );
};

export default Carts;
