import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { search_items, search_values_attributes } from "../../redux/items";
import {
    get_all_shopping_cart,
    CREATE_SHOPPING_CART_SUCCESS,
    create_shopping_cart,
    clear_status_shopping_cart
} from "../../redux/shopping_carts";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Buffer} from 'buffer';
import {
    USERNAME,
    PASSWORD,
    URLAPI
} from "../../config";
import { 
    clear_user_module, 
    create_user,
    CREATE_USER, 
    searchGroup,
    reset_password,
    RESET_PASSWORD
} from "../../redux/users";
import Modal from "../../components/modal";
import Input from "../../components/input";
import InputPassword from "../../components/input_password";
import SelectInput from "../../components/select";
import LoadingSpinner from "../../components/loading-spinner";
import {login} from "../../redux/users";
import Whatsapp from "../../components/whatsapp";

const Item = (props) => {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const _data_login = useSelector((state) => state.users_module.login);

    const [form, setForm] = useState({
        codigo_interno: "",
        titulo: "",
        descripcion: "",
        familia: "",
        id_genero: 0,
        genero: '',
        id_categoria: 0,
        categoria: '',
        id_fabricante: 0,
        fabricante: '',
        activo: false,
        imagenes: [],
        variantes: [],
        valores_atributos: [],
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getItems();
        // eslint-disable-next-line
    }, []);

    async function getItems() {
        setIsLoading(true);
        let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token = _data_login.data.token;
        }
        const params = '?id=' + id;
        const response = await search_items(token, params);
        if (response.status === 200) {
            console.log(response.data.datos[0])
            setForm({
                codigo_interno: response.data.datos[0].codigo_interno,
                titulo: response.data.datos[0].titulo,
                descripcion: response.data.datos[0].descripcion,
                familia: response.data.datos[0].familia,
                id_genero: response.data.datos[0].id_genero,
                genero: response.data.datos[0].genero,
                id_categoria: response.data.datos[0].id_categoria,
                categoria: response.data.datos[0].categoria,
                id_fabricante: response.data.datos[0].id_fabricante,
                fabricante: response.data.datos[0].fabricante,
                activo: response.data.datos[0].activo,
                imagenes: response.data.datos[0].imagenes,
                variantes: response.data.datos[0].variantes,
                valores_atributos: response.data.datos[0].valores_atributos,
            });
        }
        setIsLoading(false);
    }

    //const [radio, setRadio] = useState(0);
    const [details, setDetails] = useState([]);
    const [detailValues, setDetailValues] = useState([]);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalSesion, setModalSesion] = useState(false);
    const [idAttributeValue, setIdAttributeValue] = useState(0);

    async function getAttributeValue(id_attribute_value, id_attribute_value_fiter, atributo, y, index) {
        setIsLoading(true);
        let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token = _data_login.data.token;
        }
        const params = '?id_valor_atributo=' + id_attribute_value + '&&atributo=' + atributo + '&&id_item=' + id + '&&id_valor_atributo_filtro=' + id_attribute_value_fiter;
        console.log(params);
        const response = await search_values_attributes(token, params);
        if (response.status === 200) {
            form.valores_atributos[y][index + 1].valores = response.data.datos
        }
        setIsLoading(false);
    }

    const handleChange = (e, y, index) => {
        if (e.target.name !== 'Cantidad') {
            if (index === 0) {
                setIdAttributeValue(parseInt(e.target.value));
            }

            if (index + 1 < form.valores_atributos[y].length) {
                // eslint-disable-next-line
                form.valores_atributos[y].map((item, a) => {
                    if (a > index) {
                        item.valores = []
                    }
                })
                getAttributeValue(index === 0 ? parseInt(e.target.value) : idAttributeValue, parseInt(e.target.value), form.valores_atributos[y][index + 1].nombre, y, index)
            }
        }

        let value_attribute = e.target.value

        if (e.target.name !== 'Cantidad') {
            // eslint-disable-next-line
            form.variantes.map((a) => {
                // eslint-disable-next-line
                a.valores.map((b) => {
                    if (b.nombre_atributo === e.target.name && b.id === parseInt(e.target.value)) {
                        value_attribute = b.nombre;
                    }
                })
            })
        }

        let value = details.filter(x => x.nombre === e.target.name)
        if (value.length === 0) {
            if (e.target.name === 'Cantidad') {
                setDetails([
                    ...details,
                    {'nombre': e.target.name, 'valor': value_attribute}
                ])
            } else {
                setDetails([
                    ...details,
                    {'id_valor_atributo': parseInt(e.target.value), 'nombre': e.target.name, 'valor': value_attribute}
                ])
            }
        } else {
            if (e.target.name !== 'Cantidad') {
                value[0]['id_valor_atributo'] = parseInt(e.target.value);
            }
            value[0]['valor'] = value_attribute;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let c = 0;
        // eslint-disable-next-line
        form.valores_atributos.map((x) => {
            c = x.length;
        })
        if (details.length !== c + 1) {
            toast.error("Faltan ingresar datos", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        setDetailValues([
            ...detailValues,
            details
        ])
        setModalDetail(false);
    };

    const _create_shopping_cart = useSelector((state) => state.shopping_cart_module._create_shopping_cart);

    useEffect(() => {
        if (_create_shopping_cart.status === 201) {
            dispatch(clear_status_shopping_cart(CREATE_SHOPPING_CART_SUCCESS));
            setModalConfirm(false);
            let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
            if (_data_login.status >= 200) {
                token = _data_login.data.token;
            }
            dispatch(get_all_shopping_cart(token));
            history.push(`/carrito-compra`);
            document.getElementById("catalogo").className = "";
            document.getElementById("cotizaciones").className = "";
            document.getElementById("fabricaciones").className = "";
            document.getElementById("carrito").className = "active";
        } else if (_create_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(CREATE_SHOPPING_CART_SUCCESS));
            toast.error(_create_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_create_shopping_cart, dispatch, history]);

    function saveShoppingCart() {
        setIsLoading(true);
        let variantes = [];
        // eslint-disable-next-line
        form.variantes.map((x) => {
            let valores = [];
            // eslint-disable-next-line
            x.valores.map((y) => {
                valores.push(y.id);
            })
            variantes.push({'id_variante': x.id, 'valores': valores})
        })
        let detalles = [];
        // eslint-disable-next-line
        detailValues.map((a) => {
            let result = {'qty': 0, 'valores': []};
            // eslint-disable-next-line
            a.map((b) => {
                if (b.nombre === 'Cantidad') {
                    result['qty'] = b.valor;
                } else {
                    result['valores'].push(b.id_valor_atributo);
                }
            })
            detalles.push(result);
        })
        const data = [];
        // eslint-disable-next-line
        detalles.map((n) => {
            let filter = variantes.filter(c => JSON.stringify(c.valores) === JSON.stringify(n.valores));
            if (filter.length > 0) {
                data.push({'qty': n.qty, 'variante': filter[0].id_variante});
            }
        })
        let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token = _data_login.data.token;
        }

        dispatch(create_shopping_cart(token, {'valores': JSON.stringify(data)}));
    }

    function confirm_session() {
        console.log(_data_login)
        if (_data_login.status === 200) {
            setModalConfirm(true);
        } else {
            setModalSesion(true);
        }
    }

    const [data, setData] = useState({
        username: "",
        password: "",
    });

    const handleSubmitSesion = (e) => {
        e.preventDefault();
        if (data.username === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.password === "") {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.username !== "" && data.password !== "") {
            dispatch(login(data));
            setModalSesion(false);
        }
    };

    const _create_user = useSelector((state) => state.users_module._create_user);

    // const [modalItem, setModalItem] = useState(false);
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [modalResetPasssword, setModalResetPasssword] = useState(false);
    // const [msjItem, setMsjItem] = useState('');

    const [dataUser, setDataUser] = useState({
        identificacion: "",
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        foto: "",
        id_grupo_permiso: 0,
        url_confirmacion: window.location.origin + "/activar-cuenta",
        cliente: true,
    });

    async function getGroup() {
        const params = '?nombre=CLIENTES';
        let token1 = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token1 = _data_login.data.token
        }
        const response = await searchGroup(token1, params);
        if (response && response.data && response.data.datos) {
            setDataUser({
                ...dataUser,
                id_grupo_permiso: response.data.datos[0].id
            })
        }
    }

    useEffect(() => {
        getGroup();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_create_user.status === 201) {
            dispatch(clear_user_module(CREATE_USER));
            setIsLoading(false);
            setModalSesion(false);
            setModalCreateUser(false);
            // setMsjItem("Hemos recibido tus datos, por favor revisa la bandeja de entrada de tu correo electrónico para que continúes con el proceso de registro.");
            // setModalItem(true);
        } else if (_create_user.status !== 0) {
            dispatch(clear_user_module(CREATE_USER));
            toast.error(_create_user.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_user, dispatch, history]);

    const handleSubmitCreateUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (dataUser.identificacion === "") {
            toast.error("Debe ingresar su identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.nombres === "") {
            toast.error("Debe ingresar sus nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.apellidos === "") {
            toast.error("Debe ingresar sus apellidos", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.correo === "") {
            toast.error("Debe ingresar su correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.telefono === "") {
            toast.error("Debe ingresar su teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        let token1 = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token1 = _data_login.data.token
        }
        dispatch(create_user(token1, dataUser));
    };

    const _reset_password = useSelector((state) => state.users_module._reset_password);

    const [formUser, setFormUser] = useState({
        email: '',
        url: window.location.origin + "/confirmar-contrasena/",
    })

    const handleChangeEmail = (e) => {
        setFormUser({
            ...formUser,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        if (_reset_password.status === 200) {
            dispatch(clear_user_module(RESET_PASSWORD));
            setModalResetPasssword(false);
            setModalSesion(false);
            // setMsjItem("Se ha enviado un correo con las instrucciones para recuperar la contraseña");
            // setModalItem(true);
            setIsLoading(false);
        } else if (_reset_password.status !== 0) {
            dispatch(clear_user_module(RESET_PASSWORD));
            toast.error('No se ha podido enviar el correo de recuperación de contraseña', {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }

    }, [_reset_password, dispatch]);

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (formUser.email === '') {
            toast.error('Debe ingresar el correo electrónico', {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        dispatch(reset_password(formUser));
    }

    return (
        <div className="product_details mt-5">
            <div><ToastContainer/></div>
            <div className="container pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div id="custCarousel" className="carousel slide border-carousel" data-ride="carousel" align="center">
                            <div className="carousel-inner">
                                {form.imagenes.length > 0 ?
                                        form.imagenes.map((x, index) => (
                                            <div className={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                                <img src={URLAPI + x.url} alt="Hills"/>
                                            </div>
                                        ))
                                :
                                    <div className="carousel-item active">
                                        <img id="zoom1" src="../../../../assets/img/logo/no-image.png" data-zoom-image="../../../../assets/img/logo/no-image.png" alt="Hills"/>
                                    </div>
                                }
                            </div>

                            <a className="carousel-control-prev" href="#custCarousel" data-slide="prev">
                                <span className="carousel-control-prev-icon"></span>
                            </a>
                            <a className="carousel-control-next" href="#custCarousel" data-slide="next">
                                <span className="carousel-control-next-icon"></span>
                            </a>

                            <ol className="carousel-indicators list-inline">
                                {form.imagenes.map((x, index) => (
                                    <li className={(index === 0 ? "list-inline-item active" : "list-inline-item")}>
                                        <a id={'carousel-selector-' + index} className={(index === 0 ? "list-inline-item active" : "list-inline-item")} data-slide-to={index} data-target="#custCarousel">
                                            <img src={URLAPI + x.url} className="img-fluid"/>
                                        </a>

                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="product_d_right">
                            <form>
                                <h1>{form.titulo}</h1>
                                <div className="product_ratting">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                    </ul>
                                </div>
                                <div className="product_desc">
                                    <p>{form.descripcion}</p>
                                </div>
                                <div className="product_meta">
                                    <span>Código: {form.codigo_interno}</span><br/>
                                    <span>Categoría: {form.categoria}</span><br/>
                                    <span>Género: {form.genero}</span><br/>
                                </div>
                                <div className="product_variant quantity">
                                    <button className="button" type="button" onClick={() => {
                                        setModalDetail(true);
                                        setDetails([]);
                                        document.getElementById("Cantidad").value = '';
                                        // eslint-disable-next-line
                                        form.valores_atributos.map((row) => {
                                            // eslint-disable-next-line
                                            row.map((x, a) => {
                                                if (a !== 0) {
                                                    x.valores = [];
                                                } else {
                                                    document.getElementById(x.nombre).selectedIndex = 0;
                                                }
                                            })
                                        })
                                    }}>Agregar detalle</button>  
                                </div>
                            </form>
                            <div className="priduct_social">
                                {(detailValues.length > 0) ? (
                                    <div >
                                        <h1>Detalles</h1>
                                        {detailValues.map((row, index) => (
                                            <div className="row border_card_1 m-1">
                                                <div className="col-12 mt-1 text-right">
                                                    {/* eslint-disable-next-line */}
                                                    <a onClick={() => {
                                                        const v = detailValues.indexOf(index);
                                                        detailValues.splice(v, 1);
                                                    }}>
                                                        <img
                                                            src="../../../../assets/img/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                                {row.map((x, y) => (
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <Input
                                                            label={x.nombre}
                                                            value={x.valor}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                        <div className="d-flex justify-content-center mt-2">
                                            <button type="button" className="btn btn-outline-calendar-cliente1" style={{marginRight: 10}}
                                                    onClick={() => {
                                                        history.push(`/familia/${form.familia}`)
                                                    }}>Regresar
                                            </button>
                                            <button type="button" className="btn btn-calendar-cliente1" onClick={() => {
                                                if (detailValues.length > 0) {
                                                    confirm_session();
                                                } else {
                                                    toast.error("Debe ingresar al menos un detalle", {position: toast.POSITION.TOP_RIGHT});
                                                    return;
                                                }
                                            }}>Agregar al carrito
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-12 border_card mt-2">
                                        <p className="m-2"
                                           style={{padding: 10, width: '100%'}}>Debes agregar al menos un detalle</p>
                                    </div>
                                )}      
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Modal className="modal-main" show={modalDetail}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Características a elegir</h5>
                        <div className="form-group">
                            <Input
                                type="number"
                                id="Cantidad"
                                label="Cantidad"
                                name="Cantidad"
                                onChange={handleChange}
                            />
                        </div>
                        {(form.valores_atributos.length > 0) && (
                            form.valores_atributos.map((row, y) => (
                                row.map((x, index) => (
                                    <div className="form-group">
                                        <small className="texto2">{x.nombre}</small>
                                        <SelectInput
                                            id={x.nombre}
                                            label={x.nombre}
                                            name={x.nombre}
                                            onChange={(e) => handleChange(e, y, index)}
                                            options={(x.valores) ? x.valores.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                ))
                            ))
                        )}
                    </div>
                    <div className="text-center div_footer">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>
                            Agregar detalle
                        </button>
                        <button type="button" onClick={() => {
                            setModalDetail(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main" show={modalConfirm}>
                {isLoading && <LoadingSpinner/>}
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Agregar a carrito</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Al confirmar se agregará a tu carrito la joya
                            seleccionada con sus detalles</p>
                    </div>
                </div>
                <div className="text-center div_footer">
                    <button type="button" onClick={() => {
                        saveShoppingCart()
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Confirmar
                    </button>
                    <button type="button" onClick={() => {
                        setModalConfirm(false);
                    }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalSesion}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    <div className="text-center">
                        <h3>¡Te damos la bienvenida!</h3>
                    </div>
                    <form onSubmit={handleSubmitSesion}>
                        <Input
                            type="text"
                            name="username"
                            id="username"
                            value={data.username}
                            onChange={(e) =>
                                setData({...data, username: e.target.value})
                            }
                            label="Identificación"
                            color_label="texto1"
                        />
                        <InputPassword
                            type="password"
                            name="old_password"
                            id="old_password"
                            id_div1="show_hide_password_old1"
                            id_div2="password_old1"
                            value={data.password}
                            onChange={(e) =>
                                setData({...data, password: e.target.value})
                            }
                            label="Contraseña"
                            color_label="texto1"
                        />
                        <div className="text-right mt-3">
                            <a className="divlink" onClick={() => {
                                setModalResetPasssword(true); 
                                // setModalSesion(false);
                                setForm({...form, email: ''});
                            }}>¿Olvidé mi contraseña?</a>
                        </div>
                        <div className="text-center div_footer mt-3">
                            <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Ingresar</button>
                            <button type="button" className={`btn btn-outline-calendar-cliente1 mb-1 button_div`} onClick={() => {setModalSesion(false);}}>Cancelar</button>
                        </div>
                        <div className="text-center mt-3">
                            <small>¿No tienes una cuenta?</small><br/>
                            <a className="divlink" onClick={() => {setModalCreateUser(true)}}>Crear una cuenta aquí</a>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalCreateUser}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <div className="text-center">
                        <h3>¡Únete!</h3>
                    </div>
                    <form onSubmit={handleSubmitCreateUser}>
                        <Input
                            type="text"
                            name="identificacion"
                            id="identificacion"
                            value={dataUser.identificacion}
                            onChange={(e) =>
                                setDataUser({
                                    ...dataUser,
                                    identificacion: e.target.value,
                                    usuario: e.target.value
                                })
                            }
                            label="Identificación"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="nombres"
                            id="nombres"
                            value={dataUser.nombres}
                            onChange={(e) =>
                                setDataUser({...dataUser, nombres: e.target.value})
                            }
                            label="Nombres"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="apellidos"
                            id="apellidos"
                            value={dataUser.apellidos}
                            onChange={(e) =>
                                setDataUser({...dataUser, apellidos: e.target.value})
                            }
                            label="Apellidos"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="correo"
                            id="correo"
                            value={dataUser.correo}
                            onChange={(e) =>
                                setDataUser({...dataUser, correo: e.target.value})
                            }
                            label="Correo electrónico"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="telefono"
                            id="telefono"
                            value={dataUser.telefono}
                            onChange={(e) =>
                                setDataUser({...dataUser, telefono: e.target.value})
                            }
                            label="Teléfono"
                            color_label="texto1"
                        />
                        <div className="text-center div_footer mt-3">
                            <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Registrarse</button>
                            <button type="button" className={`btn btn-outline-calendar-cliente1 mb-1 button_div`} onClick={() => {setModalCreateUser(false);}}>Cancelar</button>
                        </div>
                    </form>
                </div>
                
            </Modal>
            <Modal className="modal-main div-modal" show={modalResetPasssword}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <form onSubmit={handleSubmitEmail}>
                        <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Restablecer contraseña 11111</h5>
                            <div className="form-group">
                                <Input
                                    label="Correo electrónico"
                                    type="email"
                                    name="email"
                                    placeholder="Correo electrónico"
                                    value={form.email}
                                    onChange={handleChangeEmail}
                                />
                            </div>
                        </div>
                        <div className="div_footer text-center">
                            <button onClick={() => {}} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Enviar</button>
                            <button type="button" onClick={() => {setModalResetPasssword(false);}} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            {/* <Modal className="modal-main" show={modalItem}>
                <div className="m-2">
                    <div className="text-center">
                        <h3>Información 1111111111111</h3>
                        <p className="mt-2">{msjItem}</p>
                    </div>
                    <div className="text-center mt-3">
                        <button onClick={() => {
                            setModalSesion(false);
                            setModalItem(false);
                            // history.push("/");
                        }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido
                        </button>
                    </div>
                </div>
            </Modal>  */}
            <Whatsapp/>
        </div>
    );
};

export default Item;
