import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {URLAPI} from '../config/index'

const Navbar = () => {
    const history = useHistory();
    
    const _get_all_family = useSelector(
        (state) => state.family_module._get_all_family
    );

    const [families, setFamilies] = useState([]);

    useEffect(() => {
        if (_get_all_family && _get_all_family.data && _get_all_family.data.datos) {
            let values = _get_all_family.data.datos.filter((x) => x.activo === true);
            setFamilies(values);
        }
        // eslint-disable-next-line
    }, [_get_all_family]);

    return (
        <footer className="footer_widgets">
            <div className="container">
                <div className="footer_top">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-8">
                            <div className="widgets_container contact_us">
                                <h3>Contáctenos</h3>
                                <div className="footer_contact">
                                    <p>Llámanos: <a>+56 600 366 0120</a></p>
                                    <p>Contacto: mayorista@zdpack.cl</p>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="ion-social-rss"></i></a></li>
                                        <li><a href="#"><i className="ion-social-googleplus"></i></a></li>

                                        <li><a href="#"><i className="ion-social-youtube"></i></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="widgets_container widget_menu">
                                <h3>Información</h3>
                                <div className="footer_menu">
                                    <ul>
                                        <li><a href="#">Quienes somos</a></li>
                                        <li><a href="#">Que hacemos</a></li>
                                        <li><a href="#">Servicio al cliente</a></li>
                                        <li><a href="#">Preguntas frecuentes</a></li>
                                        <li><a href="#">Despacho</a></li>
                                        <li><a href="#">Políticas de seguridad</a></li>
                                        <li><a href="#">Términos y condiciones</a></li>
                                        <li><a href="#">Contacto</a></li>
                                        <li><a href="#">Denuncias</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="widgets_container product_widget">
                                <h3>Familias</h3>
                                <div className="simple_product">
                                    {families.map((item) => (
                                        <div className="simple_product_items" onClick={() => {history.push(`/familia/${item.nombre}`);}} style={{cursor: 'pointer'}}>
                                            <div className="simple_product_thumb">
                                                <img
                                                    src={URLAPI + item.imagen}
                                                    alt="logo"
                                                    style={{width: '25%'}}
                                                />
                                            </div>
                                            <div className="simple_product_content">
                                                <div className="product_name">
                                                    <h3>{item.nombre}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer_bottom">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright_area">
                                <p>{new Date().getFullYear()} © INNODE</p>
                                <img src="assets/img/icon/papyel2.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>        
    );
};

export default Navbar;
