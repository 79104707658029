import {
    URLAPI,
    CITY_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_city: {
        data: [],
        status: 0,
        message: {},
    },
};

const GET_ALL_CITY_SUCCESS = "GET_ALL_CITY_SUCCESS";

export const city_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_CITY_SUCCESS:
            return {
                ...state,
                _get_all_city: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


export const get_all_city = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${CITY_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_CITY_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_CITY_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_CITY_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const clear_city = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
