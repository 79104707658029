import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
// import LoadingSpinner from "../../../components/loading-spinner";
import {
    search_catalogue_items,
    search_values_family
} from "../../redux/items";
import {Buffer} from 'buffer';
import {
    USERNAME,
    PASSWORD,
    URLAPI
} from "../../config";
import LoadingSpinner from "../../components/loading-spinner";
import Whatsapp from "../../components/whatsapp";

const Products = () => {
    const {family} = useParams();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);

    const [datas, setDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [n_page, setNPage] = useState(0);

    useEffect(() => {
        getCatalogueItems(1);
        // eslint-disable-next-line
    }, [family]);


    async function getCatalogueItems(page) {
        setIsLoading(true);
        let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token = _data_login.data.token;
        }
        const params = '?familia=' + family + '&page=' + page;
        const response = await search_catalogue_items(token, params);
        if (response.status === 200) {
            setDatas(response.data.datos);
            setNPage(response.data.n_page);
        }
        setIsLoading(false);
    }

    function more_data() {
        let count = page + 1;
        setPage(count)
        getCatalogueItems(count);
    }


    function less_data() {
        let count = page - 1;
        setPage(count)
        getCatalogueItems(count);
    }

    return (
        <div>
            {isLoading && <LoadingSpinner/>}
            <section className="product_section p_section1">
                <div className="container">
                    <div className="row">   
                        <div className="col-12">
                            <div className="product_area" style={{marginBottom: '10mm'}}> 
                                <div className="product_tab_button mt-3">
                                    <ul className="nav" role="tablist">
                                        <li>
                                            <a className="active" data-bs-toggle="tab" href="#featured" role="tab" aria-controls="featured" aria-selected="true">{family}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content" style={{display: "contents"}}>
                                    <div className="tab-pane fade show active" id="featured" role="tabpanel">
                                        <div className="product_container">
                                            <div className="custom-row product_column1">
                                                {datas && (
                                                    datas.map((x) => (
                                                        <div className="col-lg-2 col-md-3 col-sm-6 col-12 p-1" onClick={() => {history.push(`/ver/${x.id}`)}} style={{cursor: 'pointer'}}>
                                                            <div className="single_product">
                                                                <div className="product_thumb text-center">
                                                                    {x.imagen ?
                                                                        <a className="primary_img"><img src={URLAPI + x.imagen} alt=""/></a>
                                                                    :
                                                                        <a className="primary_img"><img src="../../../../assets/img/logo/no-image.png" alt=""/></a>
                                                                    }
                                                                    <div className="quick_button">
                                                                        <a href="#"> Ver </a>
                                                                    </div>
                                                                </div>
                                                                <div className="product_content">
                                                                    <div className="tag_cate">
                                                                        <span>{x.codigo_interno}</span><br/>
                                                                        <span>{x.categoria}</span>
                                                                    </div>
                                                                    <h3>{x.titulo}</h3>
                                                                    <div className="product_hover">
                                                                        <div className="product_desc">
                                                                            <p>{x.descripcion}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="shop_toolbar t_bottom">
                                    <div class="pagination">
                                        <ul>
                                            {(page > 1 && page <= n_page) &&
                                                <li><a onClick={() => {less_data()}}>{'<<'}</a></li>
                                            }
                                            <li class="current">{page}</li>
                                            {(page >= 1 && page < n_page) &&
                                                <li><a onClick={() => {more_data()}}>{'>>'}</a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
            
            <Whatsapp/>
        </div>
    );
};

export default Products;
