import React, {useState, useEffect} from "react";
import Index from "./pages/user-auth";
import {BrowserRouter as Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {SocketContext, socket} from './config/ws-client';

function App() {
  const _session = useSelector((state) => state.users_module._session_data);
  const [username, setUsername] = useState("general");
  useEffect(() => {
    if (_session.data) {
        setUsername(_session.data.usuario);
    }
  }, [_session]);

  return (
    <SocketContext.Provider value={{socket: socket(username)}}>
        <Route path="/">
            <Index/>
        </Route>
    </SocketContext.Provider>
  );
}

export default App;
