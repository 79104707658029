import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {logout, login} from '../redux/users';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../components/modal';
import Input from '../components/input';
import InputPassword from '../components/input_password';
import { get_all_shopping_cart } from "../redux/shopping_carts";
import LoadingSpinner from "./loading-spinner";
import { 
    UPDATE_PASSWORD_USER, 
    clear_user_module, 
    update_password_user, 
    update_user, 
    UPDATE_USER,
    create_user,
    CREATE_USER, 
    searchGroup,
    reset_password,
    RESET_PASSWORD
} from "../redux/users";
import { USERNAME, PASSWORD } from "../config";
import {Buffer} from 'buffer';

const Navbar = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _session_data = useSelector((state) => state.users_module._session_data);

    const [user_data, setUserData] = useState({
        id: 0,
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        grupo_permiso: {
            nombre: ""
        },
        cliente: false
    });

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    useEffect(() => {
        if (_session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data, token]);

    const handleClick = () => {
        dispatch(logout(token));
        history.push(`/`);
        document.getElementById("catalogo").className = "active";
        document.getElementById("cotizaciones").className = "";
        document.getElementById("fabricaciones").className = "";
        document.getElementById("carrito").className = "";
        menu(false);
    }

    const [modalSesion, setModalSesion] = useState(false);
    const [modalUser, setModalUser] = useState(false);
    const [modalPasssword, setModalPasssword] = useState(false);
    const [data, setData] = useState({
        username: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [new_password1, setNewPassword1] = useState('');
    const [new_password2, setNewPassword2] = useState('');
    const [old_password, setOldPassword] = useState('');

    const handleSubmitSesion = (e) => {
        e.preventDefault();
        if (data.username === "") {
            toast.error("Debe ingresar el usuario", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.password === "") {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (data.username !== "" && data.password !== "") {
            dispatch(login(data));
            setModalSesion(false);
        }
    };

    function validate_sesion (item) {
        if (item === 'cotization') {
            if (user_data.usuario) {
                history.push(`/mis-cotizaciones`);
                document.getElementById("catalogo").className = "";
                document.getElementById("cotizaciones").className = "active";
                document.getElementById("fabricaciones").className = "";
                document.getElementById("carrito").className = "";
            } else {
                setModalSesion(true);
            }
            menu(false);
        }
        if (item === 'order') {
            if (user_data.usuario) {
                history.push(`/mis-fabricaciones`);
                document.getElementById("catalogo").className = "";
                document.getElementById("cotizaciones").className = "";
                document.getElementById("fabricaciones").className = "active";
                document.getElementById("carrito").className = "";
            } else {
                setModalSesion(true);
            }
            menu(false);
        }
        if (item === 'cart') {
            if (user_data.usuario) {
                history.push(`/carrito-compra`);
                dispatch(get_all_shopping_cart(token));
                document.getElementById("catalogo").className = "";
                document.getElementById("cotizaciones").className = "";
                document.getElementById("fabricaciones").className = "";
                document.getElementById("carrito").className = "active";
            } else {
                setModalSesion(true);
            }
            menu(false);
        }
    }

    const handleChange = (e) => {
        setUserData({
            ...user_data,
            [e.target.name]: e.target.value,
        });
    };
    
    const _update_user = useSelector((state) => state.users_module._update_user);

    useEffect(() => {
        if (_update_user.status === 200) {
            dispatch(clear_user_module(UPDATE_USER));
            history.push("/");
            setModalUser(false);
            setIsLoading(false);
        } else if (_update_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_USER));
            setModalUser(false);
            setIsLoading(false);
        }
    }, [_update_user, dispatch, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (user_data.telefono === "") {
            toast.error("Debe ingresar el teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (user_data.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_user(token, user_data.id, {'telefono': user_data.telefono, 'correo': user_data.correo, 'activo': true}));
    }

    const _update_password_user = useSelector((state) => state.users_module._update_password_user);

    useEffect(() => {
        if (_update_password_user.status === 200) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            history.push("/");
            setModalPasssword(false);
            setIsLoading(false);
            setOldPassword('');
            setNewPassword1('');
            setNewPassword2('');
            dispatch(logout(token));
        } else if (_update_password_user.status !== 0) {
            dispatch(clear_user_module(UPDATE_PASSWORD_USER));
            toast.error(_update_password_user.message, {position: toast.POSITION.TOP_RIGHT});
            //setModalPasssword(false);
            setIsLoading(false);
        }
    }, [_update_password_user, dispatch, history, token]);
    
    const handleSubmitPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (old_password === "") {
            toast.error("Debe ingresar la contraseña actual", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (new_password1 === "") {
            toast.error("Debe ingresar la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (new_password2 === "") {
            toast.error("Debe ingresar la confirmación de la nueva contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (new_password1 !== new_password2) {
            toast.error("Las contraseña nueva y su confirmación no coinciden", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(update_password_user(token, user_data.id, {'old_password': old_password, 'new_password1': new_password1, 'new_password2': new_password2}));
    }

    function menu(value) {
        if (value === true) {
            document.getElementById("menu_mobile").className = "Offcanvas_menu_wrapper active";
        } else {
            document.getElementById("menu_mobile").className = "Offcanvas_menu_wrapper";
        }
    }

    const _data_login = useSelector((state) => state.users_module.login);
    const _create_user = useSelector((state) => state.users_module._create_user);

    const [modal, setModal] = useState(false);
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [modalResetPasssword, setModalResetPasssword] = useState(false);
    const [msj, setMsj] = useState('');

    const [dataUser, setDataUser] = useState({
        identificacion: "",
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        foto: "",
        id_grupo_permiso: 0,
        url_confirmacion: window.location.origin + "/activar-cuenta",
        cliente: true,
    });

    async function getGroup() {
        const params = '?nombre=CLIENTES';
        let token1 = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token1 = _data_login.data.token
        }
        const response = await searchGroup(token1, params);
        if (response && response.data && response.data.datos) {
            setDataUser({
                ...dataUser,
                id_grupo_permiso: response.data.datos[0].id
            })
        }
    }

    useEffect(() => {
        getGroup();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_create_user.status === 201) {
            dispatch(clear_user_module(CREATE_USER));
            setIsLoading(false);
            setModalCreateUser(false);
            setMsj("Hemos recibido tus datos, por favor revisa la bandeja de entrada de tu correo electrónico para que continúes con el proceso de registro.");
            setModal(true);
        } else if (_create_user.status !== 0) {
            dispatch(clear_user_module(CREATE_USER));
            toast.error(_create_user.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_user, dispatch, history]);

    const handleSubmitCreateUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (dataUser.identificacion === "") {
            toast.error("Debe ingresar su identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.nombres === "") {
            toast.error("Debe ingresar sus nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.apellidos === "") {
            toast.error("Debe ingresar sus apellidos", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.correo === "") {
            toast.error("Debe ingresar su correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (dataUser.telefono === "") {
            toast.error("Debe ingresar su teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        let token1 = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token1 = _data_login.data.token
        }
        dispatch(create_user(token1, dataUser));
    };

    const _reset_password = useSelector((state) => state.users_module._reset_password);

    const [form, setForm] = useState({
        email: '',
        url: window.location.origin + "/confirmar-contrasena/",
    })

    const handleChangeEmail = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        if (_reset_password.status === 200) {
            dispatch(clear_user_module(RESET_PASSWORD));
            setModalResetPasssword(false);
            setMsj("Se ha enviado un correo con las instrucciones para recuperar la contraseña");
            setModal(true);
            setIsLoading(false);
        } else if (_reset_password.status !== 0) {
            dispatch(clear_user_module(RESET_PASSWORD));
            toast.error('No se ha podido enviar el correo de recuperación de contraseña', {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }

    }, [_reset_password, dispatch]);

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.email === '') {
            toast.error('Debe ingresar el correo electrónico', {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        dispatch(reset_password(form));
    }

    return (
        <>
            <div className="off_canvars_overlay">
            </div>
            <div className="Offcanvas_menu">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="canvas_open">
                                <a onClick={() => {menu(true);}}><i className="ion-navicon"></i></a>
                            </div>
                            <div id="menu_mobile" className="Offcanvas_menu_wrapper">
                                <div className="canvas_close">
                                    <a onClick={() => {menu(false);}}><i className="ion-android-close"></i></a>  
                                </div>
                                <div className="top_right text-right">
                                    <ul>
                                        {user_data.usuario ?
                                            <li className="top_links"><a href="#"><i className="fa fa-user-o"></i> {user_data.nombres + ' ' + user_data.apellidos}</a>
                                                <ul className="dropdown_links">
                                                    <li><a onClick={() => {setModalUser(true); menu(false);}}><i className="fa fa-user"></i> Editar usuario</a></li>
                                                    <li><a onClick={() => {
                                                        setOldPassword('');
                                                        setNewPassword1('');
                                                        setNewPassword2('');
                                                        setModalPasssword(true);
                                                        menu(false);
                                                    }}><i className="fa fa-key"></i> Cambiar contraseña</a></li>
                                                    <li><a onClick={handleClick}><i className="fa fa-power-off"></i> Cerrar sesión</a></li>
                                                </ul>
                                            </li>
                                        :
                                            <li className="top_links"><a href="#"><i className="fa fa-user-o"></i> Usuario</a>
                                                <ul className="dropdown_links">
                                                    <li>
                                                        <a onClick={() => {
                                                            setData({username: '', password: ''});
                                                            setModalSesion(true);
                                                            menu(false);
                                                        }}><i className="fa fa-address-book"></i> Iniciar sesión</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => {
                                                            setDataUser({...dataUser, identificacion: '', usuario: '', nombres: '', apellidos: '', correo: '', telefono: ''});
                                                            setModalCreateUser(true);
                                                            menu(false);
                                                        }}><i className="fa fa-user-plus"></i> Crea cuenta</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <div className="home_contact">
                                    <div className="contact_box">
                                        <label>CONTACTO</label>
                                        <p>mayorista@zdpack.cl</p>
                                    </div>
                                    <div className="contact_box">
                                        <label>Llámanos</label>
                                        <p>+56 600 366 0120</p>

                                    </div>
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li className="active"><a onClick={() => {
                                            history.push(`/`);
                                            document.getElementById("catalogo").className = "active";
                                            document.getElementById("cotizaciones").className = "";
                                            document.getElementById("fabricaciones").className = "";
                                            document.getElementById("carrito").className = "";
                                            menu(false);
                                        }}>Catálogo <i className="fa fa-book"></i></a></li>
                                        <li><a onClick={() => {validate_sesion('cotization');}}>Mis cotizaciones <i className="fa fa-file"></i></a></li>
                                        <li><a onClick={() => {validate_sesion('order');}}>Mis fabricaciones <i className="fa fa-clock-o"></i></a></li>
                                        <li><a onClick={() => {validate_sesion('cart');}}>Ver carrito <i className="fa fa-shopping-cart"></i></a></li>
                                    </ul>
                                </div>
                                <div className="Offcanvas_footer">
                                    <div className="copyright_area">
                                        <p>{new Date().getFullYear()} © INNODE</p>
                                        <img src="assets/img/icon/papyel2.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header_area">
                <div className="header_middel">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="home_contact">
                                    <div className="contact_box">
                                        <label>CONTACTO</label>
                                        <p>mayorista@zdpack.cl</p>
                                    </div>
                                    <div className="contact_box">
                                        <label>Llámanos</label>
                                        <p>+56 600 366 0120</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-4">
                                <div className="logo">
                                    <a href="" onClick={() => {
                                        history.push(`/`);
                                    }}><img src="assets/img/logo/logo.png" alt=""/></a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-7 col-6">
                                <div className="top_right text-right">
                                    <ul>
                                        {user_data.usuario ?
                                            <li className="top_links"><a href="#"><i className="fa fa-user-o"></i> {user_data.nombres + ' ' + user_data.apellidos}</a>
                                                <ul className="dropdown_links">
                                                    <li><a onClick={() => {setModalUser(true);}}><i className="fa fa-user"></i> Editar usuario</a></li>
                                                    <li><a onClick={() => {
                                                        setOldPassword('');
                                                        setNewPassword1('');
                                                        setNewPassword2('');
                                                        setModalPasssword(true);
                                                    }}><i className="fa fa-key"></i> Cambiar contraseña</a></li>
                                                    <li><a onClick={handleClick}><i className="fa fa-power-off"></i> Cerrar sesión</a></li>
                                                </ul>
                                            </li>
                                        :
                                            <li className="top_links"><a href="#"><i className="fa fa-user-o"></i> Usuario</a>
                                                <ul className="dropdown_links">
                                                    <li>
                                                        <a onClick={() => {
                                                            setData({username: '', password: ''});
                                                            setModalSesion(true);
                                                            menu(false);
                                                        }}><i className="fa fa-address-book"></i> Iniciar sesión</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => {
                                                            setDataUser({...dataUser, identificacion: '', usuario: '', nombres: '', apellidos: '', correo: '', telefono: ''});
                                                            setModalCreateUser(true);
                                                            menu(false);
                                                        }}><i className="fa fa-user-plus"></i> Crea cuenta</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_bottom sticky-header">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="main_menu_inner">
                                    <div className="logo_sticky">
                                        <a onClick={() => {
                                            history.push(`/`);
                                            document.getElementById("catalogo").className = "active";
                                            document.getElementById("cotizaciones").className = "";
                                            document.getElementById("fabricaciones").className = "";
                                            document.getElementById("carrito").className = "";
                                        }}><img src="assets/img/logo/logo.png" alt=""/></a>
                                    </div>
                                    <div className="main_menu">
                                        <nav>
                                            <ul>
                                                <li id="catalogo" className="active"><a onClick={() => {
                                                    history.push(`/`);
                                                    document.getElementById("catalogo").className = "active";
                                                    document.getElementById("cotizaciones").className = "";
                                                    document.getElementById("fabricaciones").className = "";
                                                    document.getElementById("carrito").className = "";
                                                }}>Catálogo <i className="fa fa-book"></i></a></li>
                                                <li id="cotizaciones"><a onClick={() => {validate_sesion('cotization');}}>Mis cotizaciones <i className="fa fa-file"></i></a></li>
                                                <li id="fabricaciones"><a onClick={() => {validate_sesion('order');}}>Mis fabricaciones <i className="fa fa-clock-o"></i></a></li>
                                                <li id="carrito"><a onClick={() => {validate_sesion('cart');}}>Ver carrito <i className="fa fa-shopping-cart"></i></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Modal className="modal-main" show={modalSesion}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    <div className="text-center">
                        <h3>¡Te damos la bienvenida!</h3>
                    </div>
                    <form onSubmit={handleSubmitSesion}>
                        <Input
                            type="text"
                            name="username"
                            id="username"
                            value={data.username}
                            onChange={(e) =>
                                setData({...data, username: e.target.value})
                            }
                            label="Identificación"
                            color_label="texto1"
                        />
                        <InputPassword
                            type="password"
                            name="old_password"
                            id="old_password"
                            id_div1="show_hide_password_old"
                            id_div2="password_old"
                            value={data.password}
                            onChange={(e) =>
                                setData({...data, password: e.target.value})
                            }
                            label="Contraseña"
                            color_label="texto1"
                        />
                        <div className="text-right mt-3">
                            <a className="divlink" onClick={() => {
                                setModalResetPasssword(true); 
                                // setModalSesion(false);
                                setForm({...form, email: ''});
                            }}>¿Olvidé mi contraseña?</a>
                        </div>
                        <div className="text-center div_footer mt-3">
                            <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Ingresar</button>
                            <button type="button" className={`btn btn-outline-calendar-cliente1 mb-1 button_div`} onClick={() => {setModalSesion(false);}}>Cancelar</button>
                        </div>
                        <div className="text-center mt-3">
                            <small>¿No tienes una cuenta?</small><br/>
                            <a className="divlink" onClick={() => {setModalCreateUser(true)}}>Crear una cuenta aquí</a>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalCreateUser}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <div className="text-center">
                        <h3>¡Únete!</h3>
                    </div>
                    <form onSubmit={handleSubmitCreateUser}>
                        <Input
                            type="text"
                            name="identificacion"
                            id="identificacion"
                            value={dataUser.identificacion}
                            onChange={(e) =>
                                setDataUser({
                                    ...dataUser,
                                    identificacion: e.target.value,
                                    usuario: e.target.value
                                })
                            }
                            label="Identificación"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="nombres"
                            id="nombres"
                            value={dataUser.nombres}
                            onChange={(e) =>
                                setDataUser({...dataUser, nombres: e.target.value})
                            }
                            label="Nombres"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="apellidos"
                            id="apellidos"
                            value={dataUser.apellidos}
                            onChange={(e) =>
                                setDataUser({...dataUser, apellidos: e.target.value})
                            }
                            label="Apellidos"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="correo"
                            id="correo"
                            value={dataUser.correo}
                            onChange={(e) =>
                                setDataUser({...dataUser, correo: e.target.value})
                            }
                            label="Correo electrónico"
                            color_label="texto1"
                        />
                        <Input
                            type="text"
                            name="telefono"
                            id="telefono"
                            value={dataUser.telefono}
                            onChange={(e) =>
                                setDataUser({...dataUser, telefono: e.target.value})
                            }
                            label="Teléfono"
                            color_label="texto1"
                        />
                        <div className="text-center div_footer mt-3">
                            <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Registrarse</button>
                            <button type="button" className={`btn btn-outline-calendar-cliente1 mb-1 button_div`} onClick={() => {setModalCreateUser(false);}}>Cancelar</button>
                        </div>
                    </form>
                </div>
                
            </Modal>  
            <Modal className="modal-main div-modal" show={modalUser}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <form onSubmit={handleSubmit}>
                        <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Editar usuario</h5>
                            <div className="form-group">
                                <Input
                                    label="Nombres"
                                    name="nombres"
                                    value={user_data.nombres}
                                    disabled={true}
                                />
                                <Input
                                    label="Apellidos"
                                    name="apellidos"
                                    value={user_data.apellidos}
                                    disabled={true}
                                />
                                <Input
                                    label="Nombre visible"
                                    name="usuario"
                                    value={user_data.usuario}
                                    disabled={true}
                                />
                                <Input
                                    label="Teléfono"
                                    name="telefono"
                                    value={user_data.telefono}
                                    onChange={handleChange}
                                />
                                <Input
                                    label="Dirección de correo electrónico"
                                    name="correo"
                                    value={user_data.correo}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button onClick={() => {}} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Guardar</button>
                            <button type="button" onClick={() => {setModalUser(false);}} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main div-modal" show={modalResetPasssword}>
                <div className="m-2">
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <form onSubmit={handleSubmitEmail}>
                        <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Restablecer contraseña</h5>
                            <div className="form-group">
                                <Input
                                    label="Correo electrónico"
                                    type="email"
                                    name="email"
                                    placeholder="Correo electrónico"
                                    value={form.email}
                                    onChange={handleChangeEmail}
                                />
                            </div>
                        </div>
                        <div className="div_footer text-center">
                            <button onClick={() => {}} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Enviar</button>
                            <button type="button" onClick={() => {setModalResetPasssword(false);}} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main div-modal" show={modalPasssword}>
                <form onSubmit={handleSubmitPassword}>
                    <div><ToastContainer/></div>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Cambiar contraseña</h5>
                        <div className="form-group">
                        <InputPassword
                            type="password"
                            name="old_password"
                            id="old_password"
                            id_div1="show_hide_password_old"
                            id_div2="password_old"
                            label="Contraseña actual"
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <InputPassword
                            type="password"
                            name="new_password1"
                            id="new_password1"
                            id_div1="show_hide_password_new"
                            id_div2="password_new"
                            label="Nueva contraseña"
                            value={new_password1}
                            onChange={(e) => setNewPassword1(e.target.value)}
                        />
                        <InputPassword
                            type="password"
                            name="new_password2"
                            id="new_password2"
                            id_div1="show_hide_password_repeat"
                            id_div2="password_repeat"
                            label="Confirmar contraseña"
                            value={new_password2}
                            onChange={(e) => setNewPassword2(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button onClick={() => {}} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Guardar</button>
                        <button type="button" onClick={() => {setModalPasssword(false);}} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar</button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main" show={modal}>
                <div className="m-2">
                    <div className="text-center">
                        <h3>Información</h3>
                        <p className="mt-2">{msj}</p>
                    </div>
                    <div className="text-center mt-3">
                        <button onClick={() => {
                            setModal(false);
                            setModalSesion(false);
                            // history.push("/");
                        }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Navbar;
