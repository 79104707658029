import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {reset_password_confirm, clear_user_module, RESET_PASSWORD_CONFIRM} from '../../redux/users';
import {useParams, useHistory} from 'react-router-dom';
import InputPassword from '../../components/input_password';
import Button from '../../components/button';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    let history = useHistory();
    let dispatch = useDispatch();
    const _reset_password_confirm = useSelector((state) => state.users_module._reset_password_confirm);
    const {uid, token} = useParams();
    const [form, setForm] = useState({
        new_password1: '',
        new_password2: '',
        uid: uid,
        token: token,
    })

    useEffect(() => {
        if (_reset_password_confirm.status === 200) {
            dispatch(clear_user_module(RESET_PASSWORD_CONFIRM));
            toast.success('Se ha cambiado la contraseña correctamente', {position: toast.POSITION.TOP_RIGHT});
            //esperar 5 segundos
            setTimeout(() => {
                history.push('/');
            }, 5000);
        } else if (_reset_password_confirm.status !== 0) {
            toast.error(_reset_password_confirm.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(RESET_PASSWORD_CONFIRM));

        }
    }, [_reset_password_confirm, dispatch, history]);


    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate data
        if (form.new_password1 === '') {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.new_password2 === '') {
            toast.error("Debe ingresar la contraseña de validación", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.new_password1 !== form.new_password2) {
            toast.error("Las contraseñas no coinciden", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(reset_password_confirm(form));
    }

    return (
        <>
            <div class="breadcrumbs_area">
                <div class="container">   
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb_content">
                                <h3>Recuperar contraseña</h3>
                            </div>
                        </div>
                    </div>
                </div>         
            </div>
            <div class="customer_login">
                <div><ToastContainer/></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-12 text-center">
                            <img style={{width: '50%'}} src={window.location.origin + "/assets/img/logo/forgot-password.png"} alt="branding logo"/>
                        </div>
                        <div class="col-md-6 col-12" style={{alignContent: 'center'}}>
                            <form className="mb-2" onSubmit={handleSubmit}>
                                <InputPassword
                                    type="password"
                                    name="new_password1"
                                    id="new_password1"
                                    id_div1="show_hide_password_new"
                                    id_div2="password_new"
                                    label="Nueva contraseña"
                                    placeholder="Nueva contraseña"
                                    value={form.new_password1}
                                    onChange={handleChange}
                                />
                                <InputPassword
                                    type="password"
                                    name="new_password2"
                                    id="new_password2"
                                    id_div1="show_hide_password_repeat"
                                    id_div2="password_repeat"
                                    label="Confirmar contraseña"
                                    placeholder="Confirmar contraseña"
                                    value={form.new_password2}
                                    onChange={handleChange}
                                />

                                <Button
                                    text="Cambiar contraseña"
                                    type="submit"
                                    className={`btn btn-calendar-cliente1 mt-3 button_div`}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
