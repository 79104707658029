import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {family_module} from "./families";
import {banner_image_module} from "./banner_images";
import {shopping_cart_module} from "./shopping_carts";
import { item_module } from "./items";
import { city_module } from "./cities";
import { quotation_module } from "./quotation";
import { order_module } from "./order";
import { type_payment_module } from "./type_payment";
import { webpay_module } from "./webpay";

const rootReducer = combineReducers({
    users_module: users_module,
    family_module: family_module,
    banner_image_module: banner_image_module,
    shopping_cart_module: shopping_cart_module,
    item_module: item_module,
    city_module: city_module,
    quotation_module: quotation_module,
    order_module: order_module,
    type_payment_module: type_payment_module,
    webpay_module: webpay_module
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
