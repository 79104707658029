import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import LoadingSpinner from "../../components/loading-spinner";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI, ORDER_DOWNLOAD_PDF_PATH} from "../../config";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../components/modal";
import Input from "../../components/input";
import {get_all_order, clear_status_order, payment_order, PAYMENT_ORDER_SUCCESS} from "../../redux/order";
import { theme } from "../../config";
import Whatsapp from "../../components/whatsapp";
import DivRadio from "../../components/div-radio";
import { create_webpay, clear_status_webpay, CREATE_WEBPAY_SUCCESS } from "../../redux/webpay";

const ItemOrder = (props) => {
    const history = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();
    const _payment_order = useSelector((state) => state.order_module._payment_order);
    const _get_all_type_payment = useSelector((state) => state.type_payment_module._get_all_type_payment);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState({
        fecha_creacion: "",
        codigo_interno: "",
        estado: "",
        cotizacion: {
            detalles: [],
            valor_anticipo: 0,
            costo: 0,
            total: 0,
        }
    });

    const [typePayments, setTypePayments] = useState([]);

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
    });

    const _get_all_order = useSelector((state) => state.order_module._get_all_order);
    //const _delete_item_shopping_cart = useSelector((state) => state.shopping_cart_module._delete_item_shopping_cart);


    useEffect(() => {
        if (_get_all_order.data && _get_all_order.data.datos) {
            let value = _get_all_order.data.datos.filter(x => parseInt(x.id) === parseInt(id));
            if (value.length > 0) {
                setData(value[0]);
            }
            setConfig(_get_all_order.data.configuraciones);
        }
    }, [_get_all_order, id]);

    useEffect(() => {
        if (_get_all_type_payment.data && _get_all_type_payment.data.datos) {
            let values = _get_all_type_payment.data.datos.filter(x => x.activo === true);
            if (values.length > 0) {
                setTypePayments(values);
            }
        }
    }, [_get_all_type_payment, id]);

    const [modalPayment, setModalPayment] = useState(false);
    const [file, setFile] = useState('');
    const [item, setItem] = useState([]);
    const [id_type_payment, setId_type_payment] = useState(null);
    const [modalWebpay, setModalWebpay] = useState(false);
    const [url_ws, setUrlWs] = useState('');
    const [token_ws, setTokenWs] = useState('');

    useEffect(() => {
        if (_payment_order.status === 200) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_order(token, 1));
            setModalPayment(false);
            setId_type_payment(null);
            history.push(`/mis-fabricaciones`);
        } else if (_payment_order.status !== 0) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            toast.error(_payment_order.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_payment_order, dispatch, token, history, id]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (file === "") {
            toast.error("Debe seleccionar un comprobante", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let datas = new FormData();
        datas.append(`comprobante`, file);
        datas.append(`id_tipo_pago`, id_type_payment);
        setIsLoading(true);
        dispatch(payment_order(token, id, datas));
    }

    const _create_webpay = useSelector((state) => state.webpay_module._create_webpay);

    useEffect(() => {
        if (_create_webpay.status === 201) {
            setIsLoading(false);
            dispatch(clear_status_webpay(CREATE_WEBPAY_SUCCESS));
            if (_create_webpay && _create_webpay.data && _create_webpay.data.datos) {
                setTokenWs(_create_webpay.data.datos.token);
                setUrlWs(_create_webpay.data.datos.url);
                setModalWebpay(true);
                // const btn_ws = document.getElementById('btn_ws');
                // btn_ws.click()
                // setModalWebpay(false);
            }
        } else if (_create_webpay.status !== 0) {
            dispatch(clear_status_webpay(CREATE_WEBPAY_SUCCESS));
            setIsLoading(false);
            toast.error(_create_webpay.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_webpay, dispatch]);

    function create_webpay_plus(type_payment_id){
        setIsLoading(true);
        let advance = parseFloat(data.cotizacion.total) - parseFloat(data.cotizacion.valor_anticipo);
        let datas = new FormData();
        datas.append(`id`, data.id);
        datas.append(`id_tipo_pago`, type_payment_id);
        datas.append(`tipo`, 'order');
        datas.append(`monto`, advance);
        dispatch(create_webpay(token, datas));
    }

    return (
        <div className="product_details mt-5">
            <div><ToastContainer/></div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2>{data.codigo}</h2>
                    </div>
                    <div className="col-6 text-right">
                        <span style={{color: theme(data.estado.toUpperCase())}}>{data.estado.toUpperCase()}</span>
                    </div>
                    <div className="col-12 mt-2">
                        <strong>Fecha de creación: </strong><span>{data.fecha_creacion}</span>
                    </div>
                    {(data) &&
                        <>
                            {(data.estado === 'Pendiente inicio') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Vamos a iniciar el proceso de
                                            fabricación de tus joyas, te informaremos por correo electrónico cuando estén listas
                                            para retirar.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'En proceso') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Estamos en proceso de fabricación de
                                            tus joyas, te informaremos por correo electrónico cuando estén listas para
                                            retirar.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'Fabricado') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Hemos terminado la fabricación de tus
                                            joyas, por favor debes realizar el pago del saldo pendiente y cargar el comprobante
                                            en esta página.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'Finalizada') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Hemos validado tu pago, tu fabricación
                                            está lista para el envío.</h4>
                                    </div>
                                </div>
                            )}
                            <div class="col-12 mt-3">
                                <div class="table_desc">
                                    <div class="cart_page table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th class="product_thumb">Imagen</th>
                                                    <th class="product_name">Código interno</th>
                                                    <th class="product_name">Producto</th>
                                                    <th class="product_quantity">Cantidad</th>
                                                    <th class="product_quantity">Precio</th>
                                                    <th class="product_quantity">Subtotal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.cotizacion.detalles.map((item) => (
                                                    <tr data-bs-toggle="modal" data-bs-target="#modal_box" style={{cursor: 'pointer'}} onClick={() => {setItem(item);}}>
                                                        <td class="product_thumb"><img src={URLAPI + item.imagenes[0].url} alt="" style={{width: '30%'}}/></td>
                                                        <td class="product_name">{item.codigo_interno}</td>
                                                        <td class="product_name">{item.titulo}</td>
                                                        <td class="product_quantity">{item.cantidad}</td>
                                                        <td class="product-price">{config.moneda} {parseFloat(item.precio).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                        <td class="product_total">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>   
                                    </div>
                                </div>
                            </div>
                            <div class="coupon_area">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="coupon_code right">
                                            <h3>Totales</h3>
                                            <div class="coupon_inner">
                                                <div class="cart_subtotal">
                                                    <p>Anticipo de fabricación</p>
                                                    <p class="cart_amount">{config.moneda} {parseFloat(data.cotizacion.valor_anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <small>Corresponde
                                                    al {config.porcentaje_anticipo}% del total de la fabricación, depósito o
                                                    transferencia al confirmar la fabricación</small>
                                                <div class="cart_subtotal ">
                                                    <p>Envio</p>
                                                    <p class="cart_amount">{config.moneda} {parseFloat(data.cotizacion.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>

                                                <div class="cart_subtotal">
                                                    <p>Total</p>
                                                    <p class="cart_amount">{config.moneda} {parseFloat(data.cotizacion.total).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <div class="checkout_btn">
                                                    {(data.estado === 'Fabricado') &&
                                                        <div style={{textAlign: "left"}} className="mb-3">
                                                            <div class="cart_subtotal">
                                                                <p>Métodos de pago</p>
                                                            </div>
                                                            {typePayments &&
                                                                typePayments.map(x => (
                                                                    <DivRadio 
                                                                        id={x.id}
                                                                        title={x.nombre}
                                                                        image={x.imagen}
                                                                        children={
                                                                            <div className="text-center">
                                                                                {(x.pasarela === true) ? (
                                                                                    <button className="btn btn-calendar-cliente1" style={{margin: 1}}
                                                                                            onClick={() => {
                                                                                                create_webpay_plus(x.id)
                                                                                            }}>Continuar
                                                                                    </button>                        
                                                                                ) : (
                                                                                    <button className="btn btn-calendar-cliente1" onClick={() => {
                                                                                        setModalPayment(true);
                                                                                        setId_type_payment(x.id);
                                                                                    }} style={{margin: 1}}>Cargar comprobante
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                    {(data && data.cotizacion.detalles.length > 0 && data.estado !== 'Fabricado') ? (
                                                        // eslint-disable-next-line
                                                        <a href={`${URLAPI}${ORDER_DOWNLOAD_PDF_PATH}${id}/`} target="_blank"
                                                        className="btn btn-calendar-cliente1">Descargar orden de fabricación</a>
                                                    ) : (
                                                        <>
                                                            {/* eslint-disable-next-line */}
                                                            <a href={`${URLAPI}${ORDER_DOWNLOAD_PDF_PATH}${id}/`} target="_blank"
                                                            className="btn btn-calendar-cliente1" style={{margin: 1}}>Descargar orden de
                                                                fabricación</a>
                                                            {/* <button className="btn btn-calendar-cliente1" onClick={() => {
                                                                setModalPayment(true);
                                                            }} style={{margin: 1}}>Cargar comprobante
                                                            </button> */}
                                                        </>
                                                    )}
                                                    <button style={{marginRight: 5}} onClick={() => {
                                                            history.push(`/mis-fabricaciones`);
                                                        }}>Atras
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </>
                    }
                    
                </div>
            </div> 
            <Modal className="modal-main" show={modalPayment}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cargar comprobante</h5>
                        <Input
                            name="file"
                            label="Archivo"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="text-center div_footer">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Cargar</button>
                        <button type="button" onClick={() => {
                            setModalPayment(false);
                            setId_type_payment(null);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <div class="modal fade" id="modal_box" tabindex="-1" role="dialog"  aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal_body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        {item &&
                                            <div id="custCarousel" className="carousel slide border-carousel" data-ride="carousel" align="center">
                                                <div className="carousel-inner">
                                                    {item.imagenes ?
                                                            item.imagenes.map((x, index) => (
                                                                <div className={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                                                    <img src={URLAPI + x.url} alt="Hills"/>
                                                                </div>
                                                            ))
                                                    :
                                                        <div className="carousel-item active">
                                                            <img id="zoom1" src="../../../../assets/img/logo/no-image.png" data-zoom-image="../../../../assets/img/logo/no-image.png" alt="Hills"/>
                                                        </div>
                                                    }
                                                </div>

                                                <a className="carousel-control-prev" href="#custCarousel" data-slide="prev">
                                                    <span className="carousel-control-prev-icon"></span>
                                                </a>
                                                <a className="carousel-control-next" href="#custCarousel" data-slide="next">
                                                    <span className="carousel-control-next-icon"></span>
                                                </a>

                                                <ol className="carousel-indicators list-inline">
                                                    {item.imagenes &&
                                                        // eslint-disable-next-line
                                                        item.imagenes.map((x, index) => (
                                                            <li className={(index === 0 ? "list-inline-item active" : "list-inline-item")}>
                                                                {/* eslint-disable-next-line */}
                                                                <a id={'carousel-selector-' + index} className={(index === 0 ? "list-inline-item active" : "list-inline-item")} data-slide-to={index} data-target="#custCarousel">
                                                                    {/* eslint-disable-next-line */}
                                                                    <img src={URLAPI + x.url} className="img-fluid"/>
                                                                </a>

                                                            </li>
                                                        ))
                                                    }
                                                </ol>
                                            </div>  
                                        }
                                    </div> 
                                    <div class="col-12">
                                        <div class="modal_right">
                                            <div class="modal_title mb-10">
                                                <small>{item.codigo_interno}</small>
                                                <h2>{item.titulo}</h2> 
                                            </div>
                                            <div class="modal_price mb-10">
                                                <span class="new_price">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</span>    
                                            </div>
                                            <div class="modal_description mb-15">
                                                <p>{item.descripcion}</p>    
                                            </div> 
                                            <div class="modal_social row">
                                                <h2>Detalles</h2>
                                                {item.valores &&
                                                    item.valores.map(a => (
                                                        <div className="col-4 mb-2">
                                                            <small>{a.atributo}</small><br/>
                                                            <strong>{a.valor}</strong>
                                                        </div>
                                                    ))
                                                } 
                                            </div>      
                                        </div>    
                                    </div>    
                                </div>     
                            </div>
                        </div>    
                    </div>
                </div>
            </div> 
            <Modal className="modal-main" show={modalWebpay}>
                {isLoading && <LoadingSpinner/>}
                <form action={url_ws}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Webpay plus</h5>
                        <img src="../../../../assets/img/logo/webpay_plus.png" data-zoom-image="../../../../assets/img/logo/no-image.png"/>
                        <Input
                            name="token_ws"
                            value={token_ws}
                            style={{display: 'none'}}
                        />
                    </div>
                    <div className="text-center div_footer">
                        {/* eslint-disable-next-line */}
                        <input id="btn_ws" type="submit" className="btn btn-calendar-cliente1 button_div" value="Generar pago" formtarget="_blank" onClick={() => {setModalWebpay(false);}}></input>
                        <button type="button" onClick={() => {
                            setModalWebpay(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div mt-1`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Whatsapp/>
        </div>
    );
};

export default ItemOrder;
