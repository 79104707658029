import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { URLAPI } from "../../config";
import {ReactComponent as ReactLogo} from '../../eclipse.svg';
import Footer from '../../components/footer'
import Whatsapp from "../../components/whatsapp";

const Catalogues = (props) => {
    const history = useHistory();

    const _get_all_family = useSelector(
        (state) => state.family_module._get_all_family
    );

    const _get_all_banner_image = useSelector(
        (state) => state.banner_image_module._get_all_banner_image
    );

    const [families, setFamilies] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (_get_all_family && _get_all_family.data && _get_all_family.data.datos) {
            let values = _get_all_family.data.datos.filter((x) => x.activo === true);
            setFamilies(values);
        }
        // eslint-disable-next-line
    }, [_get_all_family]);

    useEffect(() => {
        if (_get_all_banner_image && _get_all_banner_image.data && _get_all_banner_image.data.datos) {
            let values = _get_all_banner_image.data.datos.filter((x) => x.activo === true);
            setImages(values);
        }
        // eslint-disable-next-line
    }, [_get_all_banner_image]);

    return (
        <div>
            {(images.length > 0) && (
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {images.map((x, index) => (
                            <div className={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                <img src={URLAPI + x.imagen} className="d-block w-100" alt="..."/>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            )}

            <section className="banner_section">
                <div className="container">
                    <div className="row mt-5">
                        {families.map((item) => (
                            <div className="col-lg-4 col-md-6 mb-1" onClick={() => {history.push(`/familia/${item.nombre}`);}}>
                                <div className="single_banner">
                                    <div className="banner_thumb">
                                        {item.imagen ?
                                            <div className="text-center mt-1 mb-1">
                                                <img
                                                    src={URLAPI + item.imagen}
                                                    alt="logo"
                                                    style={{width: 250, height: 300}}
                                                />
                                            </div>
                                            :
                                            <div className="text-center mt-1 mb-1">
                                                <ReactLogo width={150} heght={150}/>
                                            </div>
                                        }
                                        <div className="banner_content">
                                            <h2>{item.nombre}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            
            <section className="banner_fullwidth">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="banner_text">
                                <p>Descuentos exclusivos</p>
                                <h2>Nuevas Colecciones</h2>
                                <span>Joyas exclusivas, Fabricaciones especiales.</span>
                                <a href="">Ver más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Whatsapp/>          
            <Footer/>
        </div>
    );
};

export default Catalogues;
