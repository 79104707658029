import React, {useEffect, useState} from 'react';
import {activate_account, clear_user_module, ACTIVATE_ACCOUNT} from '../../redux/users';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory, useParams} from "react-router-dom";
import InputPassword from '../../components/input_password';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ActivateAccount = () => {
    const {user_id, token} = useParams()
    const _activate_account = useSelector((state) => state.users_module._activate_account);
    let history = useHistory();

    const dispatch = useDispatch();
    const [activate, setActivate] = useState(false);

    const [form, setForm] = useState({
        new_password: '',
        confirmation_token: token,
        user_id: user_id
    });

    useEffect(() => {
        if (_activate_account.status === 200) {
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));
            setActivate(true);
        } else if (_activate_account.status !== 0) {
            toast.error(_activate_account.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));

        }
    }, [_activate_account, dispatch, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(activate_account(form));
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    return (
        <>
            <div class="breadcrumbs_area">
                <div class="container">   
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb_content">
                                <h3>Activa tu cuenta</h3>
                            </div>
                        </div>
                    </div>
                </div>         
            </div>
            <div class="customer_login">
                <div><ToastContainer/></div>
                <div class="container">
                    {!activate ?
                        <div class="row">
                            <div class="col-md-6 col-12 text-center">
                                <img style={{width: '50%'}} src={window.location.protocol + "//" + window.location.host + "/assets/img/logo/lock-screen.png"} alt="branding logo"/>
                            </div>
                            <div class="col-md-6 col-12" style={{alignContent: 'center'}}>
                                <form className="mb-2" onSubmit={handleSubmit}>
                                    <InputPassword
                                        type="password"
                                        name="new_password"
                                        id="new_password1"
                                        id_div1="show_hide_password_new"
                                        id_div2="password_new"
                                        onChange={handleChange}
                                        label="Nueva contraseña"
                                        placeholder="Nueva contraseña"
                                        required={true}
                                    />
                                    <button type="submit" className={`btn btn-calendar-cliente1 mt-3 button_div`}>
                                        Activar mi cuenta
                                    </button>
                                </form>
                            </div>
                        </div>
                    :
                        <div className="text-center">
                            <img
                                style={{width: '50%'}}
                                src={window.location.protocol + "//" + window.location.host + "/assets/img/logo/lock-screen.png"}
                                alt="branding logo"
                            />

                            <Link to="/" className={`btn btn-calendar-cliente1 mt-3 button_div`}>
                                Iniciar sesión
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ActivateAccount;
