import {
    URLAPI,
    TOKEN_PATH,
    USERS_PATH,
    USERS_CREATE_PATH,
    USERS_UPDATE_PATH,
    USERS_UPDATE_PASSWORD_PATH,
    USER_GET_PATH,
    USER_PASSWORD_RESET_PATH,
    USER_GET_ALL_PERMISSIONS_PATH,
    USER_GROUPS_PATH,
    USER_CREATE_GROUP_PATH,
    USER_UPDATE_GROUP_PATH,
    USER_ACTIVATE_PATH,
    USER_LOGOUT_PATH,
    USER_PASSWORD_RESET_CONFIRM_PATH,
    VALUES_HOME_CLIENTS_PATH,
} from "../config/index";
import axios from "axios";
import {Buffer} from 'buffer';

const init = {
    login: {
        data: {
            token: "",
            user: ''
        },
        status: 0,
        message: {},
        authenticated: false,
    },
    _session_data: {
        data: {},
        status: 0,
        message: {},
    },
    _get_all_users: {
        data: [],
        status: 0,
        message: {},
    },
    _get_values_home_clients: {
        data: [],
        status: 0,
        message: {},
    },
    _create_user: {
        data: {},
        status: 0,
        message: {},
    },
    _update_user: {
        data: {},
        status: 0,
        message: {},
    },
    _update_password_user: {
        data: {},
        status: 0,
        message: {},
    },
    _get_all_groups: {
        data: [],
        status: 0,
        message: {},
    },
    _reset_password: {
        data: {},
        status: 0,
        message: {},
    },
    _reset_password_confirm: {
        data: {},
        status: 0,
        message: {},
    },
    _get_all_permissions: {
        data: [],
        status: 0,
        message: {},
    },
    _create_group: {
        data: {},
        status: 0,
        message: {},
    },
    _update_group: {
        data: {},
        status: 0,
        message: {},
    },
    _activate_account: {
        data: {},
        status: 0,
        message: {},
    },
};

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

const GET_ALL_USERS = "GET_ALL_USERS";

const GET_VALUES_HOME_CLIENTS = "GET_VALUES_HOME_CLIENTS";

export const CREATE_USER = "CREATE_USER";

const GET_ALL_GROUP = "GET_ALL_GROUP";

export const UPDATE_USER = "UPDATE_USER";

export const UPDATE_PASSWORD_USER = "UPDATE_PASSWORD_USER";

const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";

const GET_SESSION_DATA = "GET_SESSION_DATA";

export const CREATE_GROUP = "CREATE_GROUP";

export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";

export const LOGOUT = "LOGOUT";

export const UPDATE_GROUP = "UPDATE_GROUP";

export const RESET_PASSWORD = "RESET_PASSWORD";

export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";

export const users_module = (state = init, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
            };
        case GET_ALL_USERS:
            return {
                ...state,
                _get_all_users: action.payload,
            };
        case GET_VALUES_HOME_CLIENTS:
            return {
                ...state,
                _get_values_home_clients: action.payload,
            };
        case CREATE_USER:
            return {
                ...state,
                _create_user: action.payload,
            };
        case GET_ALL_GROUP:
            return {
                ...state,
                _get_all_groups: action.payload,
            };
        case UPDATE_USER:
            return {
                ...state,
                _update_user: action.payload,
            };
        case UPDATE_PASSWORD_USER:
            return {
                ...state,
                _update_password_user: action.payload,
            };
        case GET_SESSION_DATA:
            return {
                ...state,
                _session_data: action.payload,
            };
        case GET_ALL_PERMISSIONS:
            return {
                ...state,
                _get_all_permissions: action.payload,
            };
        case CREATE_GROUP:
            return {
                ...state,
                _create_group: action.payload,
            };

        case UPDATE_GROUP:
            return {
                ...state,
                _update_group: action.payload,
            };
        case ACTIVATE_ACCOUNT:
            return {
                ...state,
                _activate_account: action.payload,
            };
        case LOGOUT:
            return init;

        case RESET_PASSWORD:
            return {
                ...state,
                _reset_password: action.payload,
            };
        case RESET_PASSWORD_CONFIRM:
            return {
                ...state,
                _reset_password_confirm: action.payload,
            };
        default:
            return state;
    }
};

export const login = (data) => async (dispatch) => {
    try {
        const token = Buffer.from(`${data.username}:${data.password}`).toString('base64');
        const response = await axios.post(`${URLAPI}${TOKEN_PATH}`, data);
        console.log(response);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                data: {
                    token: token,
                    user: data.username,
                },
                status: 200,
                message: "",
                authenticated: true,
            },
        });
        dispatch(get_user_session_data(
            token,
            data.username,
            data.password
        ));
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: "Usuario o contraseña incorrecta",
                    authenticated: false,
                },
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al iniciar sesión",
                    authenticated: false,
                },
            });
        }
    }
};

export const logout = (token) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USER_LOGOUT_PATH}`, {}, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        console.log(response)
        dispatch({
            type: LOGOUT,
            payload: {
                data: {},
                status: 0,
                message: "",
                authenticated: false,
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: LOGOUT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: "Error al cerrar sesión",
                    authenticated: false,
                },
            });
        } else {
            dispatch({
                type: LOGOUT,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al cerrar sesión",
                    authenticated: false,
                },
            });
        }
    }
}

export const reset_password = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USER_PASSWORD_RESET_PATH}`, data);
        dispatch({
            type: RESET_PASSWORD,
            payload: {
                data: {},
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: RESET_PASSWORD,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: "Error al recuperar contraseña",
                },
            });

        } else {
            dispatch({
                type: RESET_PASSWORD,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al recuperar contraseña",
                },
            });
        }
    }
}

export const reset_password_confirm = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USER_PASSWORD_RESET_CONFIRM_PATH}`, data);
        dispatch({
            type: RESET_PASSWORD_CONFIRM,
            payload: {
                data: {},
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: RESET_PASSWORD_CONFIRM,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: "Error al confirmar contraseña",
                },
            });
        } else {
            dispatch({
                type: RESET_PASSWORD_CONFIRM,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al confirmar contraseña",
                },

            });
        }
    }
}

export const update_password_user = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${USERS_UPDATE_PASSWORD_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_PASSWORD_USER,
            payload: {
                data: {},
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: UPDATE_PASSWORD_USER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_PASSWORD_USER,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al actualizar su contraseña",
                },

            });
        }
    }
}

export const get_all_users = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${USERS_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_USERS,
            payload: {
                data: response.data.datos,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_USERS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_USERS,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los usuarios",
                },
            });
        }
    }
};

export const get_values_home_clients = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${VALUES_HOME_CLIENTS_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_VALUES_HOME_CLIENTS,
            payload: {
                data: response.data.datos,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_VALUES_HOME_CLIENTS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_VALUES_HOME_CLIENTS,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los datos",
                },
            });
        }
    }
};

export const get_user_session_data = (token, usuario, password) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${USER_GET_PATH}?usuario=${usuario}&password=${password}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_SESSION_DATA,
            payload: {
                data: response.data.datos,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_SESSION_DATA,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_SESSION_DATA,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los usuarios",
                },
            });
        }
    }
}

export const create_user = (token, data,) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USERS_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({
            type: CREATE_USER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_USER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_USER,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el usuario",
                },
            });
        }
    }
};

export const update_user = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${USERS_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_USER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_USER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_USER,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al actualizar el usuario",
                },
            });
        }
    }
};

export const get_all_groups = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${USER_GROUPS_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_GROUP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_GROUP,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_GROUP,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los grupos",
                },
            });
        }
    }
};

export const get_all_permisions = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${USER_GET_ALL_PERMISSIONS_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_PERMISSIONS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_PERMISSIONS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_PERMISSIONS,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los permisos",
                },
            });
        }
    }
}

export const create_group = (data, token) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USER_CREATE_GROUP_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_GROUP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_GROUP,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_GROUP,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el grupo",
                },
            });
        }
    }
}

export const update_group = (data, id, token) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${USER_UPDATE_GROUP_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_GROUP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_GROUP,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_GROUP,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al actualizar el grupo",
                },
            });
        }
    }
}

export const activate_account = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${USER_ACTIVATE_PATH}`, data);
        dispatch({
            type: ACTIVATE_ACCOUNT,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: ACTIVATE_ACCOUNT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: ACTIVATE_ACCOUNT,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al activar la cuenta",
                },
            });
        }
    }
}

export const searchGroup = async (token, paramsUrl) => {
    return await axios.get(`${URLAPI}${USER_GROUPS_PATH}${paramsUrl}`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const clear_user_module = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: "",
        },
    });
}
